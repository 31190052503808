<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
    <!-- Start Page Content -->
    <div id="report">
      <div v-for="(item, index) in items" class="break">
        <div class="col-12">
          <div class="row" style="display:flex; justify-content: center; align-items: center;">
            <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 1px;padding:15px 15px 15px 15px;">
              <img v-bind:src="$store.state.logo" style="height: 5cm; max-width:100%; max-height:100%;"/>
            </div>
            <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 0px;padding:15px 15px 15px 15px;">
              <h1 class="text-center">{{ companydata.cmp_nmbre }}</h1>
            </div>
            <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 0px;padding:15px 15px 15px 15px;">
              <div class="col-12">
                <div class="row border"><span><b>Proceso:</b> GESTIÓN DOCUMENTAL</span></div>
                <div class="row border"><span><b>Código:</b> GD-F-05</span></div>
                <div class="row border"><span><b>Versión:</b> 2.0</span></div>
                <div class="row border"><span><b>Fecha Revisión:</b> Septiembre 30 de 2021</span></div>
                <div class="row border"><span><b>Fecha Emisión:</b> Septiembre 30 de 2021</span></div>
              </div>
            </div>
          </div>
          <div class="row" style="text-align:center;">
            <div
              class="col-12 subtitle"
              style="border-style:solid;border-width:0px 1px 1px 1px;padding:20px 20px 20px 20px;"
            >
              CAJA Nº
              <b
                class="titlereport2"
                style="border-width:2px 2px 2px 2px;border-style:double;border-radius:10px;padding:5px 5px 5px 5px;"
              >{{ numCaja(item.caj_cdgo) }}</b>
            </div>
          </div>
          <div class="row" style="text-align:center;">
            <div
              class="col-6 subtitle"
              style="border-style:solid;border-width:0px 1px 1px 1px;padding:15px 15px 15px 15px;"
            >Sala: {{ sal_nmbre }}</div>
            <div
              class="col-6 subtitle"
              style="border-style:solid;border-width:0px 1px 1px 0px;padding:15px 15px 15px 15px;"
            >Tipo de archivo: {{ tpa_nmbre }}</div>
          </div>
          <div class="row" v-if="item.dep_unica" style="text-align:center;">
            <div
              class="col-12 subtitle"
              style="border-style:solid;border-width:0px 1px 1px 1px;padding:15px 15px 15px 15px;"
            >DEPENDENCIA: {{ item.dep_nmbre }}</div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div v-if="showProgress" style="text-align:center">
      <v-progress-circular :size="50" :width="5" color="blue" indeterminate></v-progress-circular>
    </div>
    <br />
    <div class="row justify-content-around" align="center">
      <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
      <!--
      <button
        type="button"
        id="customXLSButton"
        class="btn btn-primary"
        v-on:click="exportReport"
      >Exportar</button>
      -->
    </div>
    <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';

import moment from "moment";
import { formatDateUtc } from './../../plugins/filters';

import print from "print-js";
import XLSX from "xlsx";

export default {
  components: {
    //VProgressCircular
  },
  data() {
    return {
      message: "",
      items: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      tpa_nmbre: "",
      sal_nmbre: "",
      showProgress: false,
      exp_style: ''
    };
  },
  filters: {
    formatDateUtc
  },
  computed: {
    companydata() {
      return this.$store.state.companydata;
    }
  },
  created: function() {
    this.fecha = moment(new Date()).format("DD/MMM/YYYY hh:mm A");
    this.fetchDependencia();
    this.fetchSerie();
    this.debug = this.$route.params;
    this.tpa_nmbre = this.$route.params.tpa_nmbre;
    this.sal_nmbre = this.$route.params.sal_nmbre;
    this.exp_style = 'border:1px solid black;vertical-align:middle;font:' + this.$route.params.query.fontsize + 'px arial';
    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchDependencia() {
      let uri = "/deps/" + String(this.$store.state.company);
      this.axios.get(uri).then(response => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++) {
          for (var j = 0; j < this.dependencias.length; j++) {
            if (this.items[i].dep_id == this.dependencias[j]._id) {
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie() {
      let uri = "/series/" + String(this.$store.state.company);
      this.axios.get(uri).then(response => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++) {
          for (var j = 0; j < this.series.length; j++) {
            if (this.items[i].ser_id == this.series[j]._id) {
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }
      });
    },
    searchItems(p) {
      if (p != null) {
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = "Buscando datos";
        let uri = "/docs/report/contenido_caja";
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios
          .post(uri, p)
          .then(response => {
            var list = response.data;

            for (let i = 0; i < list.length; i++) {
              if (list[i].docs_caja.length > 0) {
                var exp = [];
                exp[0] = String(list[i].docs_caja[0].dep_id);
                exp[1] = String(list[i].docs_caja[0].ser_id);
                exp[2] = new Date(list[i].docs_caja[0].dcm_fchaini).getTime();
                exp[3] = new Date(list[i].docs_caja[0].dcm_fchafin).getTime();
                exp[4] = list[i].docs_caja[0].dcm_asunto.trim();
                var x = [0, 0, 0, 0, 0];
                var span = [0, 0, 0, 0, 0];
                list[i].dep_unica = true;
              }
              for (let m = 0; m < list[i].docs_caja.length; m++) {
                list[i].docs_caja[m].span = [0, 0, 0, 0, 0];
                /******** Dependencia */
                if (
                  exp[0].localeCompare(String(list[i].docs_caja[m].dep_id)) == 0
                ) {
                  span[0]++;
                } else {
                  x[0] = m;
                  span[0] = 1;
                  list[i].dep_unica = false;
                }
                list[i].docs_caja[x[0]].span[0] = span[0];
                exp[0] = String(list[i].docs_caja[m].dep_id);
                /**********End Dependencia */
                /******** Serie */
                if (
                  exp[1].localeCompare(String(list[i].docs_caja[m].ser_id)) == 0
                ) {
                  span[1]++;
                } else {
                  x[1] = m;
                  span[1] = 1;
                }
                list[i].docs_caja[x[1]].span[1] = span[1];
                exp[1] = String(list[i].docs_caja[m].ser_id);
                /**********End Serie */
                /******** Fecha Inicial */
                if (
                  exp[2] == new Date(list[i].docs_caja[m].dcm_fchaini).getTime()
                ) {
                  span[2]++;
                } else {
                  x[2] = m;
                  span[2] = 1;
                }
                list[i].docs_caja[x[2]].span[2] = span[2];
                exp[2] = new Date(list[i].docs_caja[m].dcm_fchaini).getTime();
                /**********End Fecha Inicial */
                /******** Fecha Final */
                if (
                  exp[3] == new Date(list[i].docs_caja[m].dcm_fchafin).getTime()
                ) {
                  span[3]++;
                } else {
                  x[3] = m;
                  span[3] = 1;
                }
                list[i].docs_caja[x[3]].span[3] = span[3];
                exp[3] = new Date(list[i].docs_caja[m].dcm_fchafin).getTime();
                /**********End Fecha Final */
                /******** Expediente */
                if (
                  exp[4].localeCompare(
                    list[i].docs_caja[m].dcm_asunto.trim()
                  ) == 0
                ) {
                  span[4]++;
                } else {
                  x[4] = m;
                  span[4] = 1;
                }
                list[i].docs_caja[x[4]].span[4] = span[4];
                exp[4] = list[i].docs_caja[m].dcm_asunto.trim();
                /**********End Expediente */
                for (let j = 0; j < this.series.length; j++) {
                  if (list[i].docs_caja[m].ser_id == this.series[j]._id) {
                    list[i].docs_caja[m].ser_nmbre = this.series[j].ser_nmbre;
                    j = this.series.length;
                  }
                }

                for (let j = 0; j < this.dependencias.length; j++) {
                  if (list[i].docs_caja[m].dep_id == this.dependencias[j]._id) {
                    list[i].docs_caja[m].dep_nmbre = this.dependencias[j].dep_nmbre;
                    j = this.dependencias.length;
                  }
                }

                if (list[i].dep_unica){
                  list[i].dep_nmbre = list[i].docs_caja[0].dep_nmbre;
                }
              }
            }

            this.items = list;

            this.showProgress = false;
          })
          .catch(err => {
            console.log(err);
            this.message = "¡Error al buscar los documentos" + err;
          });
      }
    },
    printReport(object) {
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/caja.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport() {
      var tableId = "reporteContenidoCaja";

      var report = [];
      for (var i = 0; i < this.items.length; i++) {
        let item = {};
        item.est_cdgo = this.items[i].est_cdgo;
        item.caj_cdgo = this.items[i].caj_cdgo;
        item.lgj_nmro = this.items[i].lgj_nmro;
        item.documento = this.items[i].documento;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_fchaini = moment(this.items[i].dcm_fchaini).format(
          "DD/MMM/YYYY"
        );
        if (this.items[i].dcm_fchafin != null) {
          item.dcm_fchafin = moment(this.items[i].dcm_fchafin).format(
            "DD/MMM/YYYY"
          );
        } else {
          item.dcm_fchafin = "";
        }
        item.dcm_asunto = this.items[i].dcm_asunto;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
        ["Reporte de contenido de caja"],
        ["Tipo de archivo: " + this.tpa_nmbre],
        ["Sala: " + this.sal_nmbre],
        [],
        [
          "Estante",
          "Caja",
          "Inventario",
          "Documento",
          "Dependencia",
          "Serie",
          "Fecha inicio",
          "Fecha fin",
          "Nombre del expediente"
        ]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, {
        header: [],
        skipHeader: true,
        origin: "A9"
      });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + ".xlsx"); // name of the file is 'book.xlsx'
    },
    numCaja(num) {
      var size = 4;
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    }
  } // END METHODS
};
</script>

<style>
@import "../../../public/css/caja.css";
</style>
